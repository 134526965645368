import { TFunction } from "i18next";

import { Locale } from "types";
import { MenuItem } from "./mobile-menu.types";
import { INVEST_PAGE, OFFERS_PAGE, PARTNERSHIP_PAGE } from "constants/routes.constants";
import { SECTIONS } from "constants/sections.constants";

export const menuItems = (t: TFunction, locale: Locale): MenuItem[] => [
  { name: t("pages.home"), path: `/` },
  { name: t("pages.aboutUs"), path: `/`, section: SECTIONS.aboutUs },
  { name: t("pages.offerAndRealizations"), path: OFFERS_PAGE.path[locale] },
  { name: t("pages.partnership"), path: PARTNERSHIP_PAGE.path[locale] },
  { name: t("pages.invest"), path: INVEST_PAGE.path[locale] },
];
