import React from "react";
import classNames from "classnames";

import { TitleProps } from "./title.types";
import { convertStringToHtmlSpan } from "utils/text.utils";

import { TitleDetail } from "assets/icons";

import styles from "./title.module.scss";

export const Title: React.FC<TitleProps> = ({
  children,
  containerClassName,
  className,
  size = "small",
  iconColor,
  textColor,
  align = "left",
}) => {
  const title = typeof children === "string" ? convertStringToHtmlSpan(children) : children;

  const titleClassNames = classNames(styles.title, className, {
    [styles.small]: size === "small",
    [styles.center]: align === "center",
    [styles.right]: align === "right",
  });

  const detailClassNames = classNames(styles.detail, {
    [styles.smallDetail]: size === "small",
  });

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <TitleDetail className={detailClassNames} style={{ color: iconColor || "#3E6C6B" }} />
      <h2 className={titleClassNames} style={{ color: textColor }}>
        {title}
      </h2>
    </div>
  );
};
