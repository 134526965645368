import React from "react";
import { useTranslation } from "react-i18next";

import { SliderButtons } from "components";
import { SECTIONS } from "constants/sections.constants";
import { Trail } from "components/ui/trail/trail";

import { Arrow } from "assets/icons";

import styles from "./slider-content.module.scss";

interface Props {
  total: number;
  nextSlide: () => void;
  prevSlide: () => void;
}

export const SliderContent: React.FC<Props> = ({ nextSlide, prevSlide, total }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Trail open dark>
        {t("offers.title")}
        {t("offers.titleSecondPart")}
      </Trail>
      <p className={styles.description}>{t("offers.description")}</p>
      <div className={styles.row}>
        <a href={`#${SECTIONS.currentOffers}`}>
          <button type="button" className={styles.button} onClick={() => null}>
            {t("offers.seeOffers")}
            <Arrow className={styles.arrow} />
          </button>
        </a>
        <SliderButtons onLeftClick={prevSlide} onRightClick={nextSlide} total={total} />
      </div>
    </div>
  );
};
