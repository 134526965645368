import React from "react";
import classNames from "classnames";

import { EnterTitleProps } from "./enter-title.types";

import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import styles from "./enter-title.module.scss";

export const EnterTitle: React.FC<EnterTitleProps> = ({ children, containerClassName, className, section }) => {
  return (
    <a href={section}>
      <div className={classNames(styles.container, containerClassName)}>
        <h5 className={classNames(styles.text, className)}>{children}</h5>
        <Arrow className={styles.arrow} />
      </div>
    </a>
  );
};
