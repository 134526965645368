import React from "react";
import { useTranslation } from "react-i18next";

import { EnterTitle, SliderButtons } from "components";
import { SliderOffer } from "models";
import { getLinkPath } from "utils";
import { SINGLE_OFFER_PAGE } from "constants/routes.constants";

import styles from "./realization.module.scss";

interface Props {
  item?: SliderOffer;
  nextSlide: () => void;
  prevSlide: () => void;
  total: number;
}

export const Realization: React.FC<Props> = ({ item, nextSlide, prevSlide, total }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const offerPath = (id: number) => getLinkPath(SINGLE_OFFER_PAGE.path.pl, { id });

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{item?.attributes.address}</h2>
      <p className={styles.description}>{item?.attributes.shortDescription}</p>
      {item?.id && <EnterTitle section={offerPath(item.id)}>{t("common.seeRealization")}</EnterTitle>}
      <SliderButtons onLeftClick={prevSlide} onRightClick={nextSlide} total={total} />
    </div>
  );
};
