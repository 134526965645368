import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useState } from "react";

import { i18next } from "translations/instance";
import { Locale, LocaleParam, RouteConstant } from "types";
import { LOCALES } from "constants/locales.constants";
import { routes } from "config";

export const useLocale = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { locale } = useParams<LocaleParam>();

  const [currentLocale, setLocale] = useState<Locale>(LOCALES.PL);

  const changeLocale = (newLocale: Locale) => {
    i18next.changeLanguage(newLocale, () => {
      const pathArray = pathname.split("/");
      pathArray[1] = newLocale;

      const route = routes.find((pageRoute) => pageRoute.path[currentLocale] === pathArray[2]);
      const newLocalePath = route?.path[newLocale];
      if (newLocalePath) {
        pathArray[2] = newLocalePath;
      }

      const newPath = pathArray.join("/");
      navigate(newPath);
    });
  };

  useDidUpdate(
    () => {
      setLocale(locale as Locale);
    },
    [locale],
    true,
  );

  const prepareLocalePath = (path: string) => `/${locale}/${path}`;

  const getLocalePath = (route: RouteConstant) => {
    // if (locale === "pl" || locale === "en") {
    //   return route.path[locale];
    // }
    return route.path.pl;
  };

  return { locale: currentLocale as Locale, prepareLocalePath, getLocalePath, setLocale, changeLocale };
};
