import React from "react";
import classNames from "classnames";

import banner from "assets/images/banner.webp";

import styles from "./partnership-section.module.scss";

interface Props {
  title: string;
  description: string;
  image?: string;
  reverse?: boolean;
}

export const PartnershipSection: React.FC<Props> = ({ title, description, image, reverse }) => {
  return (
    <div className={classNames(styles.container, { [styles.containerReverse]: reverse })}>
      <img src={image || banner} alt="Hall with doors" className={styles.image} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};
