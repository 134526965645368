// eslint-disable
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSubmit } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useTranslation } from "react-i18next";

import { ContactSection, FetchingError, Footer, Header, NoContent } from "components";
import { OfferSlider } from "./slider/offer-slider";
import { Gallery } from "./gallery/gallery";
import { OfferDescription } from "./description/offer-description";
import { getOffer } from "api";
import { getImageUrl } from "utils";
import { OfferModel } from "models";
import { StrapiSingularResponse } from "types";
import { FullImageView } from "pages/offer/full-image-view/full-image-view";
import { DotLoader } from "components/ui/dot-loader/dot-loader";
import { OfferSeo } from "./seo/offer-seo";

import styles from "./offer-details.module.scss";

export const OfferDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [offer, setOffer] = useState<StrapiSingularResponse<OfferModel> | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);
  const [error, setError] = useState<boolean>(false);

  useDidUpdate(() => {
    if (currentImageIndex !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [currentImageIndex]);

  const { submit, onSubmitSuccess, onSubmitError, onSubmitRequestStart } = useSubmit(getOffer);
  onSubmitRequestStart(() => setError(false));
  onSubmitSuccess(({ response }) => {
    setOffer(response);
    setLoading(false);
  });
  onSubmitError(() => {
    setError(true);
    setLoading(false);
  });

  useDidUpdate(
    () => {
      if (id) {
        submit({ params: { id }, queryParams: { populate: "*" } });
      }
    },
    [id],
    true,
  );

  const sliderOffers = useMemo(() => {
    if (!offer?.data) return [];
    return offer.data.attributes.images.data.map((item) => {
      const imageAttributes = item.attributes;
      const image = getImageUrl(
        "large" in imageAttributes.formats ? imageAttributes.formats.large.url : imageAttributes.formats.medium.url,
      );
      const imageAlt = imageAttributes.alternativeText || offer.data.attributes.address;

      return {
        image,
        alt: imageAlt,
        attributes: offer.data.attributes,
      };
    });
  }, [offer]);

  const galleryImages = sliderOffers;

  const showLoader = loading;
  const showContent = !loading && !error && offer;
  const showError = !loading && error;
  const showNoContent = !loading && !error && !offer;

  return (
    <div>
      {showLoader && (
        <div className={styles.loaderWrapper}>
          <DotLoader size="large" />
        </div>
      )}
      {showError && (
        <div className={styles.loaderWrapper}>
          <FetchingError
            title={t("common.fetchingError.title")}
            subtitle={t("common.fetchingError.description")}
            showButton
          />
        </div>
      )}
      {showNoContent && (
        <div className={styles.loaderWrapper}>
          <NoContent title={t("common.noContent.title")} subtitle={t("common.noContent.description")} showButton />
        </div>
      )}
      {showContent && (
        <>
          <OfferSeo offer={offer?.data.attributes || null} />
          <Header theme="dark" />
          <OfferSlider sliderOffer={sliderOffers.slice(0, 6)} />
          <OfferDescription
            description={offer.data.attributes.longDescription}
            price={offer.data.attributes.price}
            sold={offer.data.attributes.sold}
            sellDate={offer.data.attributes.sellDate}
          />
          <Gallery sliderOffer={galleryImages} setCurrentImageIndex={setCurrentImageIndex} />
          <ContactSection />
          <Footer />
        </>
      )}
      {currentImageIndex !== null && (
        <FullImageView
          currentIndex={currentImageIndex}
          items={galleryImages}
          setCurrentImageIndex={setCurrentImageIndex}
        />
      )}
    </div>
  );
};
