import React, { useRef, useState } from "react";
import Slider from "react-slick";

import { SliderController, SliderItem } from "components";
import { prepareSliderSettings } from "./slider.constants";
import { SliderProps } from "./slider.types";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./slider.module.scss";

export function SliderComponent<T extends SliderItem>({ sliderItems, contentRenderer }: SliderProps<T>) {
  const sliderRef = useRef<Slider>(null);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const beforeChange = (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex);
  const changeSlide = (index: number) => () => sliderRef.current?.slickGoTo(index);
  const nextSlide = () => sliderRef.current?.slickNext();
  const prevSlide = () => sliderRef.current?.slickPrev();

  const settings = prepareSliderSettings(beforeChange);

  const currentItem = sliderItems[currentIndex];

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.slider}>
          <Slider ref={sliderRef} {...settings} infinite={sliderItems.length > 1}>
            {sliderItems.map(({ image, alt }, index) => {
              return (
                <div key={index} className={styles.box}>
                  <img src={image} alt={alt} className={styles.image} />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className={styles.content}>
          <SliderController
            slides={sliderItems}
            changeSlide={changeSlide}
            currentIndex={currentIndex}
            className={styles.mobileController}
          />
          {contentRenderer(currentItem, nextSlide, prevSlide, sliderItems.length)}
        </div>
      </div>
      <SliderController
        slides={sliderItems}
        changeSlide={changeSlide}
        currentIndex={currentIndex}
        className={styles.desktopController}
        fullWidth
      />
    </div>
  );
}
