import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { PageProps } from "./page.types";

import styles from "./page.module.scss";

export const Page: React.FC<PageProps> = ({ children, name, description }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Helmet>
        <title>{t(name)}</title>
        <meta name="description" content={t(description)} />
      </Helmet>
      <div className={styles.container}>{children}</div>
    </>
  );
};
