import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { resources } from "translations/resources";
import { STORAGE_FIELDS } from "constants/storage.constants";

const setTranslationInstance = () => {
  i18next.use(initReactI18next).init({
    resources,
    lng: "pl",
    // lng: "en",
  });

  i18next.on("languageChanged", (lng: string) => {
    localStorage.setItem(STORAGE_FIELDS.language, lng);
  });
};

export { i18next, setTranslationInstance };
