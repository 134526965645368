import { client } from "api/client";
import { ServerErrorType } from "api/api.types";
import { CoordsModel } from "models";
import { StrapiMultiResponse } from "types";
import { CoordsQueryParams } from "./coords.types";

export const getCoords = client.createRequest<
  StrapiMultiResponse<CoordsModel>,
  null,
  ServerErrorType,
  CoordsQueryParams
>()({
  method: "GET",
  endpoint: `/api/coords`,
  options: {
    timeout: 0,
  },
});
