import React from "react";
import { Helmet } from "react-helmet";

import { OfferModel } from "models";

interface Props {
  offer: OfferModel | null;
}

export const OfferSeo: React.FC<Props> = ({ offer }) => {
  if (!offer) return null;

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Helmet>
        {/* Global Meta tags */}
        <title>{offer.address} | Mieszkania po remoncie</title>
        <meta name="description" content={offer.shortDescription} />

        {/* Facebook Meta Tags */}
        <meta name="Mieszkania po remoncie" content={offer.shortDescription} />
        <meta property="og:title" content={`${offer.address} | Mieszkania po remoncie`} />
        <meta property="og:description" content={offer.shortDescription} />
        <meta property="og:image" content={offer.images.data[0].attributes.url} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content={`${offer.address} | Mieszkania po remoncie`} />
        <meta name="twitter:description" content={offer.shortDescription} />
        <meta name="twitter:image" content={offer.images.data[0].attributes.url} />
      </Helmet>
    </>
  );
};
