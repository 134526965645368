import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";
import { SliderOffer } from "models";
import { useWindowSize } from "hooks";

import styles from "./gallery.module.scss";

interface Props {
  sliderOffer: SliderOffer[];
  setCurrentImageIndex: (index: number) => void;
}

export const Gallery: React.FC<Props> = ({ sliderOffer, setCurrentImageIndex }) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Title>{t("offers.gallery")}</Title>

      <section className={styles.content}>
        {sliderOffer.map((offer, index) => (
          <button
            type="button"
            className={styles.offerImage}
            key={offer.image}
            onClick={() => {
              if (width > 992) {
                setCurrentImageIndex(index);
              }
            }}
          >
            <img src={offer.image} alt={offer.alt} className={styles.image} />
          </button>
        ))}
      </section>
    </div>
  );
};
