export const home = {
  banner: {
    title: "Sprzedaż <mieszkań> po remoncie",
    description:
      "Jesteśmy zespołem pasjonatów nieruchomości, którzy z ogromnym zapałem tworzą wyjątkowe przestrzenie mieszkalne. Nasza historia zaczęła się od marzeń o odmienianiu zwykłych mieszkań w prawdziwe oazy komfortu i stylu. Dziś, z dumą możemy powiedzieć, że przekształciliśmy te marzenia w rzeczywistość dla naszych Klientów.",
  },

  introTitle: "",
  description:
    "Witajcie na naszej stronie internetowej poświęconej wyjątkowym mieszkaniom po remoncie! Jesteśmy dumni, że możemy Wam zaprezentować unikalną ofertę nieruchomości, które przeszły starannie przemyślany i profesjonalny proces odnowy. Nasza pasja do projektowania wnętrz oraz troska o detale sprawiają, że każde z naszych mieszkań to prawdziwy klejnot gotowy do zamieszkania. Pragniemy podzielić się z Wami nie tylko naszą ofertą, ale także inspiracjami, poradami dotyczącymi aranżacji oraz obszerną wiedzą na temat nieruchomości. Zapraszamy do odkrywania piękna i funkcjonalności naszych mieszkań oraz doznawania radości zakupu gotowego do życia miejsca, które możecie nazwać swoim domem.",

  about: {
    title: "O nas",
    description:
      "Jesteśmy zespołem pasjonatów nieruchomości, którzy z ogromnym zapałem tworzą wyjątkowe przestrzenie mieszkalne. Nasza historia zaczęła się od marzeń o odmienianiu zwykłych mieszkań w prawdziwe oazy komfortu i stylu. Dziś, z dumą możemy powiedzieć, że przekształciliśmy te marzenia w rzeczywistość dla naszych Klientów.\n\nPrzez lata zdobyliśmy bogate doświadczenie, przeprowadzając ponad 50 udanych transakcji sprzedaży mieszkań po remoncie. Każda z tych nieruchomości to efekt starannego planowania, kreatywnego projektowania wnętrz oraz precyzyjnego wykonania. Nasze mieszkania nie tylko zachwycają swoją estetyką, ale także funkcjonalnością, której priorytetem jest stworzenie przestrzeni idealnie dopasowanej do potrzeb i stylu życia naszych Klientów.\n\nNasze podejście opiera się na zaufaniu, profesjonalizmie oraz pasji do tworzenia unikalnych mieszkań. Każdy detal jest dla nas istotny – od wyboru najwyższej jakości materiałów, przez perfekcyjne wykończenia, aż po dbałość o detale, które nadają każdemu z naszych projektów wyjątkowy charakter.\n\nWierzymy, że mieszkanie to nie tylko przestrzeń, ale także historia, emocje i marzenia. Dlatego przykładamy ogromną wagę do tego, aby każde z naszych mieszkań po remoncie opowiadało unikalną historię, którą będziecie mogli kontynuować.\n\nZapraszamy do zapoznania się z naszą ofertą oraz do odkrywania, dlaczego nasze mieszkania po remoncie są tak wyjątkowe. Działamy z pasją i zaangażowaniem, gotowi spełnić Wasze oczekiwania odnośnie idealnego miejsca do życia",
  },

  wantToBuy: "Chcesz <kupić>, <sprzedać> \nlub <zamienić> mieszkanie?",

  realizations: {
    title: "Nasze <realizacje>",

    realization1: {
      title: "ul. Kochanowskiego 32",
      imageDescription: "ul. Kochanowskiego 32",
      description: "Krótki dwu wierszowy opis danego zdjęcia, które aktualnie się wyświetla",
    },
    realization2: {
      title: "ul. Krakowska 12/3",
      imageDescription: "ul. Krakowska 12/3",
      description: "Krótki dwu wierszowy opis danego zdjęcia, które aktualnie się wyświetla",
    },
    realization3: {
      title: "ul. Szujskiego 64",
      imageDescription: "ul. Szujskiego 64",
      description: "Krótki dwu wierszowy opis danego zdjęcia, które aktualnie się wyświetla",
    },
    realization4: {
      title: "ul. Rolnicza 199",
      imageDescription: "ul. Rolnicza 199",
      description: "Krótki dwu wierszowy opis danego zdjęcia, które aktualnie się wyświetla",
    },
  },

  whatWeDo: {
    title: "Czym się zajmujemy?",
    option1: {
      title: "Oddłużanie\nmieszkań",
      description:
        "Specjalizujemy się w unikalnym podejściu do oddłużania mieszkań, pomagając odzyskać stabilność finansową naszych Klientów.",
    },
    option2: {
      title: "Usługi remontowe\nwraz z wykończeniem",
      description:
        "Mieszkania po remoncie charakteryzują się olbrzymią dokładnością wykonania, a co za tym idzie - zaufaniem naszych klientów.",
    },
    option3: {
      title: "Sprzedaż wymarzonych\nprzestrzeni mieszkalnych",
      description:
        "Stawiamy na precyzję oraz jakość wykonania, dlatego nasze mieszkania charakteryzują się pięknym wnętrzem oraz olbrzymią jakością.",
    },
  },

  offer: {
    title: "Oferta",
    rooms: "{{value}} pokoje",
    room: "{{value}} pokój",

    price: "{{value}} PLN",
    seeOffer: "Zobacz oferte",
    seeRealization: "Zobacz realizacje",
  },
};
