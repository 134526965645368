import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./description.module.scss";

export const Description: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <section className={styles.content}>
        <p className={styles.text}>{t("home.description")}</p>
      </section>
    </div>
  );
};
