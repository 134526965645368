import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";
import { howToInvestSteps } from "./how-to-invest.constants";

import styles from "./how-to-invest.module.scss";

export const HowToInvest: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Title>{t("invest.howToInvest.title")}</Title>
      <section className={styles.gridContainer}>
        {howToInvestSteps(t).map(({ title, description, icon }) => (
          <div key={title} className={styles.itemContainer}>
            {icon}
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        ))}
      </section>
    </div>
  );
};
