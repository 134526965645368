import React from "react";

import { ContactSection, Footer, Header } from "components";
import { OffersSlider } from "./slider/offers-slider";
import { CurrentOffers } from "./current-offers/current-offers";
import { Realizations } from "./realizations/realizations";

export const OffersPage: React.FC = () => {
  return (
    <div>
      <Header theme="dark" />
      <OffersSlider />
      <CurrentOffers />
      <Realizations />
      <ContactSection />
      <Footer />
    </div>
  );
};
