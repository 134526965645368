export const offers = {
  title: "Oferty",
  titleSecondPart: "i realizacje",
  description:
    "Nasze podejście opiera się na zaufaniu, profesjonalizmie oraz pasji do tworzenia unikalnych mieszkań. Każdy detal jest dla nas istotny – od wyboru najwyższej jakości materiałów, przez perfekcyjne wykończenia, aż po dbałość o detale, które nadają każdemu z naszych projektów wyjątkowy charakter.",
  getToKnowUs: "Poznaj nas",
  seeOffers: "Zobacz oferty",
  seeDetails: "Zobacz szczegóły",

  // image attributes
  image1Alt: "mieszkanie Lwowska",
  image2Alt: "mieszkanie Westerplatte",
  image3Alt: "mieszkanie Szujskiego",
  image4Alt: "mieszkanie Rolnicza",

  currentOffers: {
    title: "Aktualne oferty nieruchomości",
  },

  realizations: {
    title: "Nasze realizacje",
  },

  price: "Cena: {{value}}",
  status: "Status: {{status}}",
  rentPrice: "Czynsz: {{value}}",
  sellDate: "Sprzedano dnia: {{date}}",
  soldStatus: "Sprzedane mieszkanie",
  activeOffer: "Aktywna oferta",
  gallery: "Galeria",
};
