import React from "react";

import { CompanyDetails } from "./company-details/company-details";
import { Map } from "./map/map";

import "leaflet/dist/leaflet.css";

import styles from "./footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <div className={styles.footerContainer}>
      <CompanyDetails />
      <Map />
    </div>
  );
};
