import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ProviderProps } from "./providers.types";
import { setTranslationInstance } from "translations/instance";

setTranslationInstance();

export const Providers: React.FC<ProviderProps> = ({ children }) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};
