import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { LoaderField } from "components";
// import { useLocale } from "hooks";
import { getLinkPath } from "utils";
import { SINGLE_OFFER_PAGE } from "constants/routes.constants";
// import { Locale } from "types";

import banner from "assets/images/banner.webp";
import { AspectRatio, Arrow, BorderRadius } from "assets/icons";

import styles from "./offer.module.scss";

interface Props {
  id: number;
  image?: string;
  title: string;
  sqMeters: number | string;
  rooms: number | string;
  price: number | string;
  sold?: boolean;
  description: string;
  loading?: boolean;
}

export const Offer: React.FC<Props> = ({
  id,
  image,
  title,
  sqMeters,
  rooms,
  sold,
  price,
  description,
  loading = false,
}) => {
  const { t } = useTranslation();
  // const { prepareLocalePath, locale } = useLocale();

  // eslint-disable-next-line
  // @ts-ignore
  const offerPath = getLinkPath(SINGLE_OFFER_PAGE.path.pl, { id });
  // const offerPath = getLinkPath(SINGLE_OFFER_PAGE.path[locale as Locale], { id });
  // const localeOfferPath = prepareLocalePath(offerPath);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.imageLoader} />
      ) : (
        <div>
          <img src={image || banner} alt="Flat" className={styles.image} />
        </div>
      )}

      <div className={styles.content}>
        <div>
          {loading ? <LoaderField height="50px" /> : <h2 className={styles.title}>{title}</h2>}

          {sold && (
            <p
              className={styles.soldText}
              data-tooltip-id="123"
              data-tooltip-content="Zapraszamy do sekcji 'Nasze oferty'."
            >
              Mieszkanie sprzedane
            </p>
          )}

          <Tooltip id="123" className={styles.tooltip} />

          <div className={styles.attributesContainer}>
            <div className={styles.attribute}>
              <AspectRatio className={styles.attributeIcon} />
              {loading ? <LoaderField height="20px" width="30px" /> : <>{sqMeters}&#13217;</>}
            </div>
            <div className={styles.attribute}>
              <BorderRadius className={styles.attributeIcon} />
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading ? (
                <LoaderField height="20px" width="30px" />
              ) : rooms === 1 ? (
                t("home.offer.room", { value: rooms })
              ) : (
                t("home.offer.rooms", { value: rooms })
              )}
            </div>
          </div>
        </div>

        <div>
          {loading ? <LoaderField height="36px" /> : <h4 className={styles.price}>{price}</h4>}
          {loading ? <LoaderField height="45px" /> : <p className={styles.description}>{description}</p>}

          <Link to={offerPath}>
            <button type="button" className={styles.button} onClick={() => null} disabled={loading}>
              {sold ? t("home.offer.seeRealization") : t("home.offer.seeOffer")}
              <Arrow className={styles.arrow} />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
