export const SECTIONS = {
  aboutUs: "about-us",
  contact: "contact",
  currentOffers: "current-offers",
  offerDetails: "offer-details",
  investDetails: "invest-details",
  partnershipDetails: "partnership-details",

  problemsWeSolve: "problems-we-solve",
  ourSolutions: "our-solutions",
  useCases: "use-cases",
  team: "team",
  contactUs: "contact-us",
  finance: "finance",
  aiData: "ai-data",
  industry: "industry",
  marketing: "marketing",
};
