import { LocalizationRouteConstant, RouteConstant } from "types";

export const LOCALIZATION_PAGE: LocalizationRouteConstant = {
  path: "/:locale",
  name: "pages.home",
  auth: false,
  showNavigation: true,
};

export const HOME_PAGE: RouteConstant = {
  path: {
    pl: "/",
    en: "/",
  },
  name: "pages.homeTitle",
  description: "pages.homeDescription",
  auth: false,
  showNavigation: true,
};

export const ABOUT_PAGE: RouteConstant = {
  path: {
    pl: "/o-nas",
    en: "about-us",
  },
  name: "pages.aboutUs",
  description: "pages.aboutUsDescription",
  auth: false,
  showNavigation: true,
};

export const OFFERS_PAGE: RouteConstant = {
  path: {
    pl: "/oferta-i-realizacje",
    en: "offers-and-realizations",
  },
  name: "pages.offersTitle",
  description: "pages.offerAndRealizationsDescription",
  auth: false,
  showNavigation: true,
};

export const SINGLE_OFFER_PAGE: RouteConstant = {
  path: {
    pl: "/oferta/:id",
    en: "offer/:id",
  },
  name: "pages.singleOfferTitle",
  description: "pages.singleOfferDescription",
  auth: false,
  showNavigation: true,
} as const;

export const PARTNERSHIP_PAGE: RouteConstant = {
  path: {
    pl: "/wspolpraca",
    en: "partnership",
  },
  name: "pages.partnershipTitle",
  description: "pages.partnershipDescription",
  auth: false,
  showNavigation: true,
};

export const INVEST_PAGE: RouteConstant = {
  path: {
    pl: "/inwestuj",
    en: "invest",
  },
  name: "pages.investTitle",
  description: "pages.investDescription",
  auth: false,
  showNavigation: true,
};

export const NOT_FOUND_PAGE: RouteConstant = {
  path: {
    pl: "*",
    en: "*",
  },
  name: "pages.notFound",
  description: "pages.notFoundDescription",
  auth: false,
  showNavigation: true,
};

export const PRICING_PAGE: RouteConstant = {
  // eslint-disable-next-line
  // @ts-ignore
  path: "pricing",
  name: "Pricing",
  auth: false,
  showNavigation: true,
};
