import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "components";
import { convertStringToHtmlSpan } from "utils/text.utils";
import { Trail } from "components/ui/trail/trail";

import banner from "assets/images/banner.webp";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import styles from "./banner.module.scss";

interface Props {
  title?: string;
  description?: string;
  navigateTo?: string;
  buttonText?: string;
  image?: string;
}

export const Banner: React.FC<Props> = ({ navigateTo, title, description, buttonText, image }) => {
  const { t } = useTranslation();

  const navigateToSection = () => {
    window.location.href = navigateTo || "#what-we-do";
  };

  const bannerTitle = convertStringToHtmlSpan(title || t("home.banner.title"));

  return (
    <div className={styles.container}>
      <div className={styles.overlay} />
      <img src={image || banner} className={styles.background} alt="Real estate banner" />

      <div className={styles.content}>
        <Header />

        <section className={styles.innerContent}>
          <Trail open>{bannerTitle}</Trail>

          <p className={styles.description}>{description || t("home.banner.description")}</p>

          <button type="button" className={styles.button} onClick={navigateToSection}>
            {buttonText || t("common.getToKnowUs")}
            <Arrow />
          </button>
        </section>
      </div>
    </div>
  );
};
