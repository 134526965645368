import React from "react";
import { useTranslation } from "react-i18next";

import { Banner, ContactSection, Footer } from "components";
import { WhyWorth } from "./why-worth/why-worth";
import { HowToInvest } from "./how-to-invest/how-to-invest";
import { LastYear } from "./last-year/last-year";
import { SECTIONS } from "constants/sections.constants";

import invest from "assets/images/invest.webp";

export const InvestPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Banner
        title={t("invest.banner.title")}
        description={t("invest.banner.description")}
        buttonText={t("invest.banner.button")}
        navigateTo={`#${SECTIONS.investDetails}`}
        image={invest}
      />
      <WhyWorth />
      <HowToInvest />
      <LastYear />
      <ContactSection />
      <Footer />
    </div>
  );
};
