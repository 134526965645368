import { TFunction } from "i18next";

import exchange from "assets/images/exchange.webp";
import improve from "assets/images/improve.webp";
import renovation from "assets/images/renovation.webp";

export const partnershipSections = (t: TFunction) => [
  {
    title: t("partnership.section1.title"),
    description: t("partnership.section1.description"),
    image: exchange,
  },
  {
    title: t("partnership.section2.title"),
    description: t("partnership.section2.description"),
    reverse: true,
    image: improve,
  },
  {
    title: t("partnership.section3.title"),
    description: t("partnership.section3.description"),
    image: renovation,
  },
];
