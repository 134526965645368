import React from "react";
import { useTranslation } from "react-i18next";

import { COMPANY_DETAILS } from "constants/company-details.constants";
import { convertStringToHtmlSpan } from "utils/text.utils";

import { Logo } from "assets/icons";
import kitchen from "assets/images/kitchen.webp";

import styles from "./contact-section.module.scss";

export const ContactSection: React.FC = () => {
  const { t } = useTranslation();

  const title = convertStringToHtmlSpan(t("home.wantToBuy"));

  return (
    <div className={styles.container}>
      <img src={kitchen} className={styles.background} alt="Kuchnia" />
      <div className={styles.overlay} />

      <section className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.row}>
          <Logo className={styles.logo} />
          <a href={`tel:${COMPANY_DETAILS.PHONE}`} className={styles.phoneNumber}>
            {COMPANY_DETAILS.PHONE}
          </a>
        </div>
      </section>
    </div>
  );
};
