import React from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@hyper-fetch/react";

import { Offer, LoadingOffer, Title, FetchingError } from "components";
import { getOffers } from "api";
import { useShowContent } from "hooks/use-show-content/use-show-content.hook";
import { getImageUrl } from "utils";
import { SECTIONS } from "constants/sections.constants";

import { House } from "assets/icons";

import styles from "./current-offers.module.scss";

export const CurrentOffers: React.FC = () => {
  const { t } = useTranslation();

  const offersData = useFetch(getOffers.setQueryParams({ populate: "*", "filters[sold][$eq]": false }));
  const { showLoader, showError } = useShowContent(offersData);
  const { data } = offersData;

  const loadingArray = Array(4).fill(0);
  const offers = data?.data;
  const showNoContent = offers?.length === 0;

  return (
    <div className={styles.container} id={SECTIONS.currentOffers}>
      <Title size="small">{t("offers.currentOffers.title")}</Title>
      <div className={styles.gridContainer}>
        {showLoader && loadingArray.map((item, index) => <LoadingOffer key={index} />)}
        {showError && (
          <FetchingError title={t("common.fetchingError.title")} subtitle={t("common.fetchingError.description")} />
        )}
        {showNoContent && (
          <div className={styles.noContentWrapper}>
            <House className={styles.icon} />
            <p className={styles.noContent}>{t("common.offers.noContent")}</p>
          </div>
        )}
        {!showLoader &&
          offers?.map(({ attributes, id }) => (
            <Offer
              key={id}
              id={id}
              image={getImageUrl(attributes.thumbnail.data.attributes.formats.medium.url)}
              title={attributes.address}
              sqMeters={attributes.area}
              rooms={attributes.rooms}
              price={attributes.price}
              description={attributes.shortDescription}
            />
          ))}
      </div>
    </div>
  );
};
