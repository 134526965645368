import React from "react";
import { useTranslation } from "react-i18next";

import { Banner, ContactSection, Footer, Title } from "components";
import { SECTIONS } from "constants/sections.constants";
import { partnershipSections } from "./partnership.constants";
import { PartnershipSection } from "./partnership-section/partnership-section";
import { Description } from "./description/description";

import cooperate from "assets/images/cooperate.webp";

import styles from "./partnership.module.scss";

export const PartnershipPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Banner
        title={t("partnership.banner.title")}
        description={t("partnership.banner.description")}
        buttonText={t("partnership.banner.button")}
        navigateTo={`#${SECTIONS.partnershipDetails}`}
        image={cooperate}
      />

      <Description />

      <section className={styles.sectionContainer} id={SECTIONS.partnershipDetails}>
        <Title>{t("partnership.sectionTitle")}</Title>
        <div className={styles.sectionContent}>
          {partnershipSections(t).map(({ title, description, reverse, image }) => (
            <PartnershipSection key={title} title={title} description={description} reverse={reverse} image={image} />
          ))}
        </div>
      </section>

      <ContactSection />
      <Footer />
    </div>
  );
};
