import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";

import { House, Bag, Building } from "assets/icons";

import styles from "./what-we-do.module.scss";

export const WhatWeDo: React.FC = () => {
  const { t } = useTranslation();

  const whatWeDo = [
    {
      title: t("home.whatWeDo.option1.title"),
      description: t("home.whatWeDo.option1.description"),
      icon: <House className={styles.icon} />,
    },
    {
      title: t("home.whatWeDo.option2.title"),
      description: t("home.whatWeDo.option2.description"),
      icon: <Bag className={styles.icon} />,
    },
    {
      title: t("home.whatWeDo.option3.title"),
      description: t("home.whatWeDo.option3.description"),
      icon: <Building className={styles.icon} />,
    },
  ];

  return (
    <section className={styles.container}>
      <Title size="small">{t("home.whatWeDo.title")}</Title>

      <div className={styles.rowContainer}>
        {whatWeDo.map(({ title, description, icon }) => (
          <div key={title}>
            {icon}
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
