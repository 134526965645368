import React from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@hyper-fetch/react";

import { FetchingError, LoadingOffer, NoContent, Offer, Title } from "components";
import { getOffers } from "api";
import { useShowContent } from "hooks/use-show-content/use-show-content.hook";
import { getImageUrl } from "utils";

import styles from "./realizations.module.scss";

export const Realizations: React.FC = () => {
  const { t } = useTranslation();

  const offersData = useFetch(getOffers.setQueryParams({ populate: "*", "filters[sold][$eq]": true }));
  const { showLoader, showError, showNoContent } = useShowContent(offersData);
  const { data } = offersData;

  const loadingArray = Array(4).fill(0);
  const offers = data?.data;

  return (
    <div className={styles.container}>
      <Title size="small">{t("offers.realizations.title")}</Title>
      <div className={styles.gridContainer}>
        {showError && (
          <FetchingError title={t("common.fetchingError.title")} subtitle={t("common.fetchingError.description")} />
        )}
        {showNoContent && (
          <NoContent title={t("common.noContent.title")} subtitle={t("common.noContent.description")} />
        )}
        {showLoader && loadingArray.map((item, index) => <LoadingOffer key={index} />)}
        {!showLoader &&
          offers?.map(({ attributes, id }) => (
            <Offer
              key={id}
              id={id}
              image={getImageUrl(attributes.thumbnail.data.attributes.formats.medium.url)}
              title={attributes.address}
              sqMeters={attributes.area}
              rooms={attributes.rooms}
              price={attributes.price}
              sold={attributes.sold || false}
              description={attributes.shortDescription}
            />
          ))}
      </div>
    </div>
  );
};
