import React, { useRef } from "react";
import classNames from "classnames";

import { OfferModel } from "models";

import { CircleArrowLeft, CircleArrowRight, Close } from "assets/icons";

import styles from "./full-image-view.module.scss";

interface Props {
  currentIndex: number | null;
  items: { image: string; alt: string; attributes: OfferModel }[];
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

export const FullImageView: React.FC<Props> = ({ currentIndex, items, setCurrentImageIndex }) => {
  const ref = useRef<HTMLImageElement>(null);

  const currentOffer = currentIndex !== null && currentIndex < items.length ? items[currentIndex] : null;

  const onLeftClick = (event: React.MouseEvent) => {
    setCurrentImageIndex((prevIndex) => (prevIndex !== null ? prevIndex - 1 : null));
    event.preventDefault();
    event.stopPropagation();
  };

  const onRightClick = (event: React.MouseEvent) => {
    setCurrentImageIndex((prevIndex) => (prevIndex !== null ? prevIndex + 1 : null));
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setCurrentImageIndex(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowRight") {
      if (currentIndex === items.length - 1) return;
      setCurrentImageIndex((prevIndex) => (prevIndex !== null ? prevIndex + 1 : null));
    }

    if (event.key === "ArrowLeft") {
      if (currentIndex === 0) return;
      setCurrentImageIndex((prevIndex) => (prevIndex !== null ? prevIndex - 1 : null));
    }

    if (event.key === "Escape") {
      handleClose();
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  if (!currentOffer || currentIndex === null) return null;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions
    <div className={styles.container} onClick={() => setCurrentImageIndex(null)}>
      <button type="button" className={styles.close} onClick={handleClose}>
        <Close />
      </button>

      <button
        type="button"
        className={classNames(styles.button, styles.left)}
        onClick={onLeftClick}
        disabled={currentIndex === 0}
      >
        <CircleArrowLeft />
      </button>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src={currentOffer.image}
        alt={currentOffer.alt}
        className={styles.image}
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />

      <button
        type="button"
        className={classNames(styles.button, styles.right)}
        onClick={onRightClick}
        disabled={currentIndex === items.length - 1}
      >
        <CircleArrowRight />
      </button>
    </div>
  );
};
