import { TFunction } from "i18next";

import banner from "assets/images/banner.webp";
import exchange from "assets/images/exchange.webp";
import improve from "assets/images/improve.webp";
import renovation from "assets/images/renovation.webp";

export const offersItems = (t: TFunction) => [
  {
    image: renovation,
    alt: t("offers.image1Alt"),
  },
  {
    image: improve,
    alt: t("offers.image2Alt"),
  },
  {
    image: exchange,
    alt: t("offers.image3Alt"),
  },
  {
    image: banner,
    alt: t("offers.image4Alt"),
  },
];
