import React from "react";
import classNames from "classnames";

import { SliderControllerProps } from "./slider-controller.types";

import styles from "./slider-controller.module.scss";

export const SliderController: React.FC<SliderControllerProps> = ({
  slides,
  currentIndex,
  changeSlide,
  fullWidth,
  className,
}) => {
  const containerClass = classNames(
    fullWidth ? styles.sliderFullWidthContainer : styles.sliderOptionContainer,
    className,
  );

  const selectedClass = classNames({
    [styles.sliderOptionSelected]: !fullWidth,
    [styles.fullWidthOptionSelected]: fullWidth,
  });
  const notSelectedClass = classNames({ [styles.sliderOption]: !fullWidth, [styles.fullWidthOption]: fullWidth });

  return (
    <div className={containerClass}>
      {slides.map((_, index) => (
        <button
          type="button"
          key={index.toString()}
          onClick={changeSlide(index)}
          className={currentIndex === index ? selectedClass : notSelectedClass}
          style={{ width: fullWidth ? "100%" : "30px" }}
        />
      ))}
    </div>
  );
};
