import { useState } from "react";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Nullable } from "types";
import { WindowSizeResponse } from "./use-window-size.types";

export const useWindowSize = (
  element?: Nullable<HTMLElement>,
  onResize?: (width: number, height: number) => void,
): WindowSizeResponse => {
  const [isElementLoading, setElementLoading] = useState(true);

  const isClient = typeof window === "object";

  const getSize = () => {
    if (element) {
      const boundRect = element.getBoundingClientRect();
      setElementLoading(false);
      return {
        width: boundRect.width,
        height: boundRect.height,
      };
    }

    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = () => {
    const size = getSize();
    setWindowSize(size);
    onResize?.(size.width, size.height);
  };

  useDidUpdate(
    () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    },
    [element],
    true,
  );

  return { ...windowSize, isElementLoading };
};
