type BeforeChangeCallback = (oldIndex: number, newIndex: number) => void;

export const prepareSliderSettings = (beforeChange: BeforeChangeCallback) => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1.03,
  slidesToScroll: 1,
  centerPadding: "20px",
  arrows: false,
  className: "slider",
  beforeChange,
});

// export const sliderItems = (t: TFunction) => [
//   { image: offer, alt: t("offer.image1Alt"), description: t("offer.image1Description") },
//   {
//     image: offer,
//     alt: t("offer.image2Alt"),
//     description: t("offer.image2Description"),
//   },
//   {
//     image: offer,
//     alt: t("offer.image3Alt"),
//     description: t("offer.image3Description"),
//   },
//   {
//     image: offer,
//     alt: t("offer.image4Alt"),
//     description: t("offer.image4Description"),
//   },
//   {
//     image: offer,
//     alt: t("offer.image5Alt"),
//     description: t("offer.image5Description"),
//   },
// ];
