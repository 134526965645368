import { home } from "./home.translations";
import { common } from "./common.translations";
import { pages } from "./pages.translations";
import { offers } from "./offers.translations";
import { partnership } from "./partnership.translations";
import { invest } from "./invest.translations";
import { footer } from "./footer.translations";

export const plTranslations = {
  home,
  common,
  pages,
  offers,
  invest,
  partnership,
  footer,
};
