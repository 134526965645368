import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";
import { SECTIONS } from "constants/sections.constants";

import { BackgroundDetails } from "assets/icons";

import styles from "./why-worth.module.scss";

export const WhyWorth: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} id={SECTIONS.investDetails}>
      <BackgroundDetails className={styles.background} />
      <Title>{t("invest.whyWorth.title")}</Title>
      <p className={styles.description}>{t("invest.whyWorth.description")}</p>
    </div>
  );
};
