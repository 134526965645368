import React from "react";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Banner, Footer, ContactSection } from "components";
import { Description } from "./description/description";
import { AboutUs } from "./about-us/about-us";
import { WhatWeDo } from "./what-we-do/what-we-do";
import { Offers } from "./offers/offers";
import { Realizations } from "./realizations/realizations";
import { SECTIONS } from "constants/sections.constants";
import { useQueryParams } from "hooks";

export const LandingPage: React.FC = () => {
  const { queryObject } = useQueryParams();

  useDidUpdate(() => {
    if (queryObject?.section) {
      const pageSection = document.querySelector(`#${queryObject.section}`);
      if (pageSection) pageSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [queryObject]);

  return (
    <div>
      <Banner navigateTo={`#${SECTIONS.aboutUs}`} />
      <Description />
      <AboutUs />
      <WhatWeDo />
      <Offers />
      <ContactSection />
      <Realizations />
      <Footer />
    </div>
  );
};
