export const common = {
  contactUs: "Skontaktuj się",
  getToKnowUs: "Poznaj nas",
  seeOffer: "Zobacz oferte",
  seeRealization: "Zobacz realizacje",

  fetchingError: {
    title: "Błąd pobierania danych",
    description: "Spróbuj odświeżyć stronę",
    goBack: "Przejdź na stronę główną",
  },

  offers: {
    noContent: "Brak aktywnych ofert",
  },

  realizations: {
    noContent: "Brak dodanych realizacji",
  },

  noContent: {
    title: "Brak danych",
    description: "Brak danych do wyświetlenia",
    goBack: "Przejdź na stronę główną",
  },
};
