import React from "react";

import { SliderComponent } from "components";
import { SliderContent } from "./slider-content/slider-content";
import { SliderOffer } from "models";

import styles from "./offer-slider.module.scss";

interface Props {
  sliderOffer: SliderOffer[];
}

export const OfferSlider: React.FC<Props> = ({ sliderOffer }) => {
  return (
    <div className={styles.container}>
      <SliderComponent
        sliderItems={sliderOffer}
        contentRenderer={(item, nextSlide, prevSlide, total) => (
          <SliderContent sliderOffer={item} prevSlide={prevSlide} nextSlide={nextSlide} total={total} />
        )}
      />
    </div>
  );
};
