export const pages = {
  home: "Strona główna",
  aboutUs: "O nas",
  aboutUsDescription: "Dowiedz się jak działa nasza firma, w czym się specjalizujemy oraz jaki zakres usług oferujemy.",
  offerAndRealizations: "Oferty i realizacje",
  offerAndRealizationsDescription: "Aktualne oferty mieszkań oraz realizacje w Tarnowie i okolicach.",
  singleOffer: "Oferta",
  singleOfferDescription: "Oferta mieszkania na sprzedaż.",
  partnership: "Współpraca",
  partnershipDescription:
    "Zainwestuj swoje pieniądze w naszą firmę i zacznij zarabiać. Dowiedz się jak możesz zwiększyć swoje zarobki inwestując w firmę Mieszkania Po Remoncie.",
  invest: "Inwestuj",
  investDescription: "Zainwestuj swoje pieniądze i zarabiaj razem z nami.",
  contact: "Kontakt",
  notFound: "Podana strona nie istnieje | Mieszkania po remoncie",
  notFoundDescription: "Strona której szukasz nie została znaleziona.",

  homeTitle:
    "Strona główna | Mieszkania po remoncie - Zespół pasjonatów nieruchomości, który z ogromnym zapałem tworzy wyjątkowe przestrzenie mieszkalne.",
  homeDescription:
    "Zespół pasjonatów nieruchomości, który z ogromnym zapałem tworzy wyjątkowe przestrzenie mieszkalne. Nasza historia zaczęła się od marzeń o odmienianiu zwykłych mieszkań w prawdziwe oazy komfortu i stylu.",
  offersTitle: "Oferty i realizacje | Mieszkania po remoncie",
  singleOfferTitle: "Oferta | Mieszkania po remoncie",
  partnershipTitle: "Współpraca | Mieszkania po remoncie",
  investTitle: "Inwestuj | Mieszkania po remoncie",
};
