export const invest = {
  banner: {
    title: "Inwestuj",
    description:
      "Współpracujemy z inwestorami dysponującymi od 50 tysięcy złotych w górę. Oferujemy indywidualnie dostosowane warunki pożyczki, umożliwiając rozwijanie zyskownych projektów. Dołącz do nas i wykorzystaj nasze doświadczenie na rynku nieruchomości, by osiągnąć atrakcyjne zwroty z inwestycji.",
    button: "Dowiedz się więcej",
  },

  whyWorth: {
    title: "Dlaczego warto inwestować z nami?",
    description:
      "Wybór odpowiedniego partnera inwestycyjnego ma kluczowe znaczenie dla osiągnięcia sukcesu. Nasza sprawdzona przeszłość i zaufanie  inwestorów, którzy dołączyli do naszego zespołu, to najlepsza rekomendacja naszej wiarygodności.\n\nWspółpracując z nami, otrzymujesz szansę na inwestycję w nieruchomości przy elastycznych i dogodnych warunkach pożyczki. Nasze indywidualnie dostosowane podejście pozwala Ci wykorzystać swój kapitał w sposób, który odpowiada Twoim celom finansowym.\n\nPonadto, inwestując z nami, Twoje pieniądze są zabezpieczone przed inflacją. Inwestycje w nieruchomości to sprawdzony sposób na utrzymanie i wzrost wartości kapitału w dłuższej perspektywie czasowej. Zapewniamy Ci profesjonalną obsługę, przejrzystość i uczciwość w każdym etapie inwestycji. Dołącz do grona naszych inwestorów i razem budujmy przyszłość opartą na stabilności, zyskach i zaufaniu.",
  },

  howToInvest: {
    title: "Jak przebiega współpraca?",
    contact: {
      title: "Skontaktuj się z nami",
      description:
        "Rozpocznijmy wspólną drogę do Twojego sukcesu. Pierwszym krokiem jest nawiązanie kontaktu, by umówić się na spotkanie. Czekamy na Twój telefon lub wiadomość, aby omówić Twoje cele i plany inwestycyjne.",
    },
    setDetails: {
      title: "Ustal szczegóły",
      description:
        "Dokładne ustalenie warunków to klucz do udanej inwestycji. Razem omówimy termin pożyczki, czas trwania, okres wypowiedzenia i atrakcyjną stopę procentową. Twoje preferencje są dla nas priorytetem - skontaktuj się, aby ustalić szczegóły dostosowane do Ciebie.",
    },
    signTheContract: {
      title: "Podpisz umowę o pożyczkę",
      description:
        "Nasza transparentna umowa zapewnia Ci pewność i zabezpieczenie inwestycji. Po omówieniu warunków, przygotujemy umowę dostosowaną do Twoich potrzeb. Twoja inwestycja jest dla nas priorytetem – jesteśmy gotowi Cię wspierać na każdym kroku.",
    },
    takeTheProfit: {
      title: "Zarabiaj",
      description:
        "Po wszystkich formalnościach nadszedł czas na relaks. Czekaj na ustalony przez nas dzień miesiąca i ciesz się z zysków, które będą przybywać jak magicznie! Twój kapitał pracuje dla Ciebie. Otwórz butelkę szampana – zarabianie nigdy nie było tak przyjemne!",
    },
  },

  lastYear: {
    title: "Poprzedni rok w liczbach",
    transactions: {
      title: "14",
      subtitle: "Udanych transakcji",
      description: "Każde mieszkanie jest wykańczane z dbałością o najmniejsze szczegóły",
    },
    clients: {
      title: "23",
      subtitle: "Zadowolonych klientów",
      description: "Podstawą naszego działania jest spełnienie wszystkich oczekiwań naszych klientów.",
    },
    partners: {
      title: "12",
      subtitle: "Współpracowników",
      description:
        "Nasza firma stawia na rozwój - wszystko po to, aby tworzyć przestrzenie mieszkalne spełniające oczekiwania nawet najbardziej wymagających klientów.",
    },
  },
};
