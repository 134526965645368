import React from "react";
import classNames from "classnames";

import styles from "./loader.module.scss";

interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const LoaderField: React.FC<Props> = ({ width, height, style, className }) => {
  return (
    <div
      className={classNames(styles.loaderField, className)}
      style={{ width: width || "100%", height: height || "30px", ...style }}
    />
  );
};
