import React from "react";
import classNames from "classnames";

import styles from "./hamburger.module.scss";

interface Props {
  isOpen: boolean;
  toggleMenu: () => void;
  darkTheme?: boolean;
  className?: string;
}

export const Hamburger: React.FC<Props> = ({ isOpen, toggleMenu, darkTheme, className }) => {
  const menuClassNames = classNames({
    [styles.darkInner]: darkTheme,
    [styles.innerOpen]: isOpen,
    [styles.innerClose]: !isOpen,
  });

  return (
    <button type="button" className={classNames(styles.outer, className)} onClick={toggleMenu}>
      <div className={menuClassNames} />
    </button>
  );
};
