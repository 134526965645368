import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";
import i18next from "i18next";

import { NotFoundPage } from "pages";
import { PageRoute } from "components";
import { routes } from "config";
import { useLocale } from "hooks";
import { LOCALES } from "constants/locales.constants";
// import { Locale } from "types";

import "./assets/styles/app.scss";

export const App: React.FC = () => {
  // const navigate = useNavigate();

  const { pathname } = useLocation();
  const { setLocale } = useLocale();

  useDidMount(() => {
    // const pathLocale = pathname.split("/")[1];

    i18next.changeLanguage("pl");

    // if (pathLocale !== LOCALES.PL) {
    // if (pathLocale !== LOCALES.PL && pathLocale !== LOCALES.EN) {
    // i18next.changeLanguage("pl");
    // } else {
    //   i18next.changeLanguage(pathLocale as Locale);
    // }
  });

  // useDidMount(() => {
  //   if (!locale) {
  //     navigate(`/pl`);
  //   }
  // });

  useDidUpdate(() => {
    const pathLocale = pathname.split("/")[1];

    setLocale(LOCALES.PL);

    if (pathLocale !== LOCALES.PL) {
      // if (pathLocale !== LOCALES.PL && pathLocale !== LOCALES.EN) {
      // setLocale(LOCALES.PL);
      // navigate(`/${LOCALES.PL}/${HOME_PAGE.path.pl}`);
    } else {
      // setLocale(pathLocale);
    }
  }, [pathname]);

  return (
    <Routes>
      {/* <Route path={LOCALIZATION_PAGE.path} element={<LocalizationPage />}> */}
      {routes.map((route) => (
        <Route key={route.name} path={route.path.pl} element={<PageRoute {...route} />} />
      ))}
      <Route path="*" element={<NotFoundPage />} />
      {/* </Route> */}
    </Routes>
  );
};
