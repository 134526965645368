import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";
import { SECTIONS } from "constants/sections.constants";

import { DotsBackground } from "assets/icons";

import styles from "./description.module.scss";

export const Description: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} id={SECTIONS.aboutUs}>
      <section className={styles.content}>
        <Title>{t("partnership.descriptionTitle")}</Title>
        <p className={styles.text}>{t("partnership.description")}</p>
      </section>
      <DotsBackground className={styles.dotsBackground} />
      <section className={styles.details} />
    </div>
  );
};
