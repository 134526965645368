export const home = {
  solutions: "Solutions",
  useCases: "Use cases",
  industries: "Industries",
  company: "Company",
  pricing: "Pricing",

  tryForFree: "Try for free",

  banner: {
    title: "Lorem ipsum <dolor> sit amet consectetur adipiscing elit sed do <eiusmod tempor> incididunt",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering  attacks.",
  },

  problemsWeSolve: {
    title: "Problems we solve",
    paragraph:
      "Our mission is to secure critical infrastructure. Magellanic is cybersecurity solution to protect GNSS  communication, remote access and data integrity to enable continuity of operations in each part of critical  infrastructure. Magellanic is the complement layer to the already existing or new platforms – integration  process is cost-effective and fast.\n\n Magellanic builds data integrity and information confidentiality preventing external attacks or running commands by unauthorized user. We live in the world of processing petabytes of data in real time, complex supply chains and we need to be ready to act immediately. Magellanic keeps your infrastructure and services secure.",
  },

  ourSolutions: {
    title: "Innovative <solutions> for uncommon\nchallenges",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",

    subtitle: "Check our solutions for <complex> security problems",
    subparagraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",

    workloadIam: {
      title: "Workload IAM",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    apiSecurity: {
      title: "API Security",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    dynamicAccess: {
      title: "Dynamic Access",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    secureRemoteAccess: {
      title: "Secure Remote Access",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  },

  withMagellanic: {
    title: "With Magellanic",
    option1: "Quis autem vel eum iure reprehenderit",
    option2: "At vero eos et accusamus et iusto odio dignissimos",
    option3: "Et harum quidem rerum facilis est et expedita",
    option4: "Temporibus autem quibusdam et aut",
  },

  withoutMagellanic: {
    title: "Without Magellanic",
    option1: "Quis autem vel eum iure reprehenderit",
    option2: "At vero eos et accusamus et iusto odio dignissimos",
    option3: "Et harum quidem rerum facilis est et expedita",
    option4: "Temporibus autem quibusdam et aut",
  },

  unique: {
    title: "What makes us <unique> in the security world?",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
};
