import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";
import { COMPANY_DETAILS } from "constants/company-details.constants";
import { SECTIONS } from "constants/sections.constants";

import { Logo } from "assets/icons";

import styles from "./company-details.module.scss";

export const CompanyDetails: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.container} id={SECTIONS.contact}>
      <Title containerClassName={styles.titleContainer} className={styles.title}>
        {t("footer.contact")}
      </Title>
      <div className={styles.content}>
        <div>
          <h6 className={styles.subtitle}>{COMPANY_DETAILS.NAME}</h6>
          <p className={styles.text}>{COMPANY_DETAILS.ADDRESS}</p>
        </div>
        <div>
          <a href={`tel: ${COMPANY_DETAILS.PHONE}`} className={styles.text}>
            {COMPANY_DETAILS.PHONE}
          </a>
          <br />
          {/* <a href={`mailto: ${COMPANY_DETAILS.EMAIL}`} className={styles.text}> */}
          {/*  {COMPANY_DETAILS.EMAIL} */}
          {/* </a> */}
        </div>
        {/* <div> */}
        {/*  <p className={styles.text}> */}
        {/*    {t("footer.nip", { value: COMPANY_DETAILS.NIP })} <br /> */}
        {/*  </p> */}
        {/* </div> */}
        <Logo className={styles.logo} />
      </div>
    </footer>
  );
};
