import React from "react";

import { LoaderField } from "components";

import { AspectRatio, BorderRadius } from "assets/icons";

import styles from "./offer.module.scss";

export const LoadingOffer: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageLoader} />

      <div className={styles.content}>
        <div>
          <LoaderField height="50px" />

          <div className={styles.attributesContainer}>
            <div className={styles.attribute}>
              <AspectRatio className={styles.attributeIcon} />
              <LoaderField height="20px" width="30px" />
            </div>
            <div className={styles.attribute}>
              <BorderRadius className={styles.attributeIcon} />
              <LoaderField height="20px" width="30px" />
            </div>
          </div>
        </div>

        <div>
          <LoaderField height="36px" />
          <LoaderField height="45px" />
        </div>
      </div>
    </div>
  );
};
