import React from "react";
import { useTranslation } from "react-i18next";

import { Nullable } from "types";
import { SECTIONS } from "constants/sections.constants";

import styles from "./offer-description.module.scss";

interface Props {
  description: string;
  price: string;
  sold: Nullable<boolean>;
  sellDate?: Nullable<string>;
  rentPrice?: Nullable<string>;
}

export const OfferDescription: React.FC<Props> = ({ description, price, sold, sellDate, rentPrice }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} id={SECTIONS.offerDetails}>
      <p className={styles.description}>{description}</p>
      <h5 className={styles.subtitle}>{t("offers.price", { value: price })}</h5>

      {rentPrice && <p className={styles.status}>{t("offers.rentPrice", { value: rentPrice })}</p>}
      <p className={styles.status}>
        {t("offers.status", { status: sold ? t("offers.soldStatus") : t("offers.activeOffer") })}
      </p>
      {sellDate && (
        <p className={styles.sellDate}>{t("offers.sellDate", { date: new Date(sellDate).toLocaleDateString() })}</p>
      )}
    </div>
  );
};

/*
soldStatus: "Sprzedane mieszkanie",
  activeOffer: "Aktywna oferta",
 */
/*
status: "Status: {{status}}",
  sellDate: "Sprzedano dnia: {{date}}",
 */
