export const company = {
  banner: {
    title: "Get to <know> us",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",
  },

  aboutUs: {
    title: "About <us>",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",
  },

  team: {
    title: "Our <team>",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",

    member1: {
      firstName: "Albert",
      lastName: "Lewandowski",
      role: "CEO/CTO",
    },

    member2: {
      firstName: "Assaf",
      lastName: "Cohen",
      role: "CEO",
    },

    member3: {
      firstName: "Paweł",
      lastName: "Krzyżak",
      role: "Software engineer",
    },

    member4: {
      firstName: "Michał",
      lastName: "Boruch",
      role: "Software engineer",
    },
  },

  partners: {
    title: "Partners",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",
  },

  contactUs: {
    title: "Contact <us>",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",
  },

  advisors: {
    title: "Advisors",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",

    advisor1: {
      firstName: "Albert",
      lastName: "Lewandowski",
      description: "CEO/CTO",
    },

    advisor2: {
      firstName: "Assaf",
      lastName: "Cohen",
      description: "CEO",
    },

    advisor3: {
      firstName: "Paweł",
      lastName: "Krzyżak",
      description: "Software engineer",
    },
  },
};
