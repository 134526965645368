import React from "react";
import { createRoot } from "react-dom/client";

import { Providers } from "components";
import { App } from "app";
import { reportWebVitals } from "reportWebVitals";

import "react-tooltip/dist/react-tooltip.css";

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
