import React from "react";
import { useTranslation } from "react-i18next";

import { ContactSection, Footer, Header } from "components";

import styles from "./not-found.module.scss";

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header theme="dark" />
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.mainTitle}>404</h1>
          <h2>{t("pages.notFound")}</h2>
          <p>{t("pages.notFoundDescription")}</p>
        </div>
      </div>
      <ContactSection />
      <Footer />
    </div>
  );
};
