import { client } from "api/client";
import { ServerErrorType } from "api/api.types";
import { OfferQueryParams, OffersQueryParams } from "api/offers/offers.types";
import { OfferModel } from "models";
import { StrapiMultiResponse, StrapiSingularResponse } from "types";

export const getOffers = client.createRequest<
  StrapiMultiResponse<OfferModel>,
  null,
  ServerErrorType,
  OffersQueryParams
>()({
  method: "GET",
  endpoint: `/api/offers`,
  options: {
    timeout: 0,
  },
});

export const getOffer = client.createRequest<
  StrapiSingularResponse<OfferModel>,
  null,
  ServerErrorType,
  OfferQueryParams
>()({
  method: "GET",
  endpoint: `/api/offers/:id`,
  options: {
    timeout: 0,
  },
});
