import React from "react";
import L, { LatLngExpression } from "leaflet";
import { useFetch } from "@hyper-fetch/react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useShowContent } from "hooks/use-show-content/use-show-content.hook";
import { getCoords } from "api";
// import { useLocale } from "hooks";
import { SINGLE_OFFER_PAGE } from "constants/routes.constants";
import { getLinkPath } from "utils";
// import { Locale } from "types";
import { DotLoader } from "components/ui/dot-loader/dot-loader";

import { Arrow } from "assets/icons";
import logo from "assets/icons/po-remoncie-logo.svg";

import styles from "./map.module.scss";

const Icon = L.icon({
  iconUrl: logo,
  shadowUrl: iconShadow,
  className: styles.icon,
  popupAnchor: [-21, -21],
});

const TARNOW_COORDS: LatLngExpression = [50.01381, 20.98698];

export const Map: React.FC = () => {
  const { t } = useTranslation();
  // const { prepareLocalePath, locale } = useLocale();

  const offersData = useFetch(getCoords.setQueryParams({ populate: "*" }));
  const { showLoader } = useShowContent(offersData);
  const { data } = offersData;

  // eslint-disable-next-line
  //@ts-ignore
  const offerPath = (id: number) => getLinkPath(SINGLE_OFFER_PAGE.path.pl, { id });
  // const offerPath = (id: number) => getLinkPath(SINGLE_OFFER_PAGE.path[locale as Locale], { id });
  // const localeOfferPath = (id: number) => prepareLocalePath(offerPath(id));

  return (
    <>
      {showLoader && (
        <div className={styles.loaderContainer}>
          <DotLoader />
        </div>
      )}
      {!showLoader && (
        <MapContainer center={TARNOW_COORDS} zoom={14}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {data?.data.map((coord, index) => {
            const { attributes } = coord;
            const { lat, long } = attributes;

            const markerPosition: LatLngExpression = [+lat, +long];

            return (
              <Marker position={markerPosition} icon={Icon} key={index}>
                <Popup>
                  <h3>{attributes.label}</h3>
                  <Link to={offerPath(coord.attributes.offer.data.id)}>
                    <button type="button" className={styles.button}>
                      {t("common.seeOffer")}
                      <Arrow />
                    </button>
                  </Link>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      )}
    </>
  );
};
