import React from "react";

import styles from "./dot-loader.module.scss";

interface Props {
  size?: "normal" | "large";
}

export const DotLoader: React.FC<Props> = ({ size = "normal" }) => {
  return <span className={size === "large" ? styles.pulseLarge : styles.pulse} />;
};
