import React from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@hyper-fetch/react";

import { Title, SliderComponent, FetchingError } from "components";
import { Realization } from "./realization/realization";
import { getOffers } from "api";
import { useShowContent } from "hooks/use-show-content/use-show-content.hook";
import { getImageUrl } from "utils";
import { SliderOffer } from "models";

import "./realizations.css";

import styles from "./realizations.module.scss";

export const Realizations: React.FC = () => {
  const { t } = useTranslation();

  const offersData = useFetch(getOffers.setQueryParams({ populate: "*", "filters[sold][$eq]": true }));
  const { showLoader, showError, showNoContent } = useShowContent(offersData);
  const { data } = offersData;

  const offers = data?.data.slice(0, 5).map((item) => {
    const imageAttributes = item.attributes.thumbnail.data.attributes;
    const image = getImageUrl(imageAttributes.formats.large.url);
    const imageAlt = imageAttributes.alternativeText || item.attributes.address;

    return {
      id: item.id,
      image,
      alt: imageAlt,
      attributes: item.attributes,
    };
  });

  return (
    <div className={styles.container}>
      <Title>{t("home.realizations.title")}</Title>
      {(showError || showNoContent) && (
        <FetchingError
          title={t("common.fetchingError.title")}
          subtitle={t("common.fetchingError.description")}
          className={styles.errorContainer}
        />
      )}
      {!showLoader && offers && (
        <SliderComponent<SliderOffer>
          sliderItems={offers || []}
          contentRenderer={(sliderItem, nextSlide, prevSlide, total) => (
            <Realization item={sliderItem} nextSlide={nextSlide} prevSlide={prevSlide} total={total} />
          )}
        />
      )}
    </div>
  );
};
