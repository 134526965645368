import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SliderButtons } from "components";
import { SliderOffer } from "models";
import { SECTIONS } from "constants/sections.constants";
import { OFFERS_PAGE } from "constants/routes.constants";

import { Arrow, AspectRatio, BorderRadius, CircleArrowLeft } from "assets/icons";

import styles from "./slider-content.module.scss";

interface Props {
  nextSlide: () => void;
  prevSlide: () => void;
  sliderOffer: SliderOffer;
  total: number;
}

export const SliderContent: React.FC<Props> = ({ nextSlide, prevSlide, sliderOffer, total }) => {
  const { t } = useTranslation();

  const { attributes } = sliderOffer;

  return (
    <div className={styles.container}>
      <Link to={OFFERS_PAGE.path.pl} className={styles.backWrapper}>
        <CircleArrowLeft />
        Powrót
      </Link>
      <h2 className={styles.title}>{attributes.address}</h2>
      <p className={styles.description}>{attributes.shortDescription}</p>
      <div className={styles.attributesContainer}>
        <div className={styles.attribute}>
          <AspectRatio className={styles.attributeIcon} />
          {attributes.area}&#13217;
        </div>
        <div className={styles.attribute}>
          <BorderRadius className={styles.attributeIcon} />
          {attributes.rooms === 1
            ? t("home.offer.room", { value: attributes.rooms })
            : t("home.offer.rooms", { value: attributes.rooms })}
        </div>
      </div>
      <a href={`#${SECTIONS.offerDetails}`}>
        <button type="button" className={styles.button} onClick={() => null}>
          {t("offers.seeDetails")}
          <Arrow className={styles.arrow} />
        </button>
      </a>
      <SliderButtons onLeftClick={prevSlide} onRightClick={nextSlide} total={total} />
    </div>
  );
};
