import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NoContentProps } from "./no-content.types";
// import { useLocale } from "hooks";

import styles from "./no-content.module.scss";

export const NoContent = memo(
  ({ title = "", subtitle = "", size = "big", className = "", showButton = false }: NoContentProps) => {
    const navigate = useNavigate();
    // const { locale } = useLocale();

    const { t } = useTranslation();

    return (
      <div className={`${styles.container} ${styles[size]} ${className}`}>
        <h4>{title}</h4>
        <p>{subtitle}</p>
        {showButton && (
          <button type="button" onClick={() => navigate(`/}`)}>
            {/* <button type="button" onClick={() => navigate(`/${locale}`)}> */}
            {t("common.noContent.goBack")}
          </button>
        )}
      </div>
    );
  },
);
