export const ai = {
  banner: {
    title: "<AI> / Data",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",
  },

  section: {
    title: "<AI> / Data",
    description:
      "Magellanic is zero-trust cybersecurity platform based on Distributed Ledger to secure GNSS communication and data integrity in the critical systems from external threats like jamming, spoofing or social engineering attacks.",
  },

  boxContent: {
    option1: {
      title: "AI / Data #1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    },
    option2: {
      title: "AI / Data #2",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    },
    option3: {
      title: "AI / Data #3",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    },
    option4: {
      title: "AI / Data #4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    },
  },
};
