import { TFunction } from "i18next";

import { Wallet, BarChart, FileText, Phone } from "assets/icons";

export const howToInvestSteps = (t: TFunction) => [
  {
    icon: <Phone />,
    title: t("invest.howToInvest.contact.title"),
    description: t("invest.howToInvest.contact.description"),
  },
  {
    icon: <BarChart />,
    title: t("invest.howToInvest.setDetails.title"),
    description: t("invest.howToInvest.setDetails.description"),
  },
  {
    icon: <FileText />,
    title: t("invest.howToInvest.signTheContract.title"),
    description: t("invest.howToInvest.signTheContract.description"),
  },
  {
    icon: <Wallet />,
    title: t("invest.howToInvest.takeTheProfit.title"),
    description: t("invest.howToInvest.takeTheProfit.description"),
  },
];
