import React from "react";
import { useTranslation } from "react-i18next";

import { SliderComponent } from "components";
import { offersItems } from "./offers-slider.constants";
import { SliderContent } from "./slider-content/slider-content";

import styles from "./offers-slider.module.scss";

export const OffersSlider: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <SliderComponent
        sliderItems={offersItems(t)}
        contentRenderer={(slide, nextSlide, prevSlide, total) => (
          <SliderContent nextSlide={nextSlide} prevSlide={prevSlide} total={total} />
        )}
      />
    </div>
  );
};
