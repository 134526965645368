import React from "react";
import { useTrail, a } from "react-spring";

import styles from "./trail.module.scss";

interface Props {
  children: React.ReactNode;
  open: boolean;
  dark?: boolean;
  containerClassName?: string;
}

export const Trail: React.FC<Props> = ({ open, children, dark, containerClassName }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 60 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <div className={containerClassName}>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className={dark ? styles.trailsTextDark : styles.trailsText} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};
