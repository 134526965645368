import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { createPortal } from "react-dom";

import { MobileMenuProps } from "./mobile-menu.types";
import { menuItems } from "./mobile-menu.constants";
import { Hamburger } from "components/layout/hamburger/hamburger";
import { SECTIONS } from "constants/sections.constants";

import { Logo } from "assets/icons";

import styles from "./mobile-menu.module.scss";

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, closeMenu }) => {
  const navigate = useNavigate();
  const element = document.getElementById("modals");

  const { t } = useTranslation();
  // const { locale } = useLocale();

  if (!element) return null;

  return createPortal(
    <div className={isOpen ? styles.open : styles.close}>
      <Hamburger isOpen toggleMenu={closeMenu} className={styles.hamburger} />
      <Logo className={styles.logo} />
      {/* <img src={logo} alt="Magellanic logo" className={styles.logo} /> */}
      <section className={styles.content}>
        {menuItems(t, "pl").map(({ name, path, section }) => {
          return (
            <button
              key={name}
              type="button"
              className={classNames(styles.item)}
              onClick={() => {
                if (!section) {
                  if (path.startsWith("/")) {
                    navigate(`${path}`);
                  } else {
                    navigate(`/${path}`);
                  }
                  closeMenu();
                  return;
                }

                if (section) {
                  navigate(`${path}?section=q${section}`, { preventScrollReset: true });
                  setTimeout(() => {
                    navigate(`${path}?section=${section}`, { preventScrollReset: true });
                    closeMenu();
                  }, 0);
                }
              }}
            >
              {name}
            </button>
          );
        })}
        <button
          type="button"
          className={classNames(styles.item)}
          onClick={() => {
            const contact = document.querySelector(`#${SECTIONS.contact}`);
            if (contact) contact.scrollIntoView({ behavior: "smooth" });
            closeMenu();
          }}
        >
          {t("pages.contact")}
        </button>
      </section>
    </div>,
    element,
  );
};
