import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Hamburger, MobileMenu } from "components";
import { menuItems } from "../mobile-menu/mobile-menu.constants";
import { HOME_PAGE } from "constants/routes.constants";
// import { useLocale } from "hooks";
import { SECTIONS } from "constants/sections.constants";

import { Logo } from "assets/icons";

import styles from "./header.module.scss";

export interface HeaderProps {
  theme?: "dark" | "light";
}

export const Header: React.FC<HeaderProps> = ({ theme }) => {
  const navigate = useNavigate();

  // const { locale, prepareLocalePath } = useLocale();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => setMenuOpen((wasOpen) => !wasOpen);
  const handleMenuOpen = (isOpen: boolean) => () => setMenuOpen(isOpen);

  const checkActive = (path?: string) => {
    if (!path) return false;
    if (path === "/") return pathname === "/";
    return pathname.includes(path);
  };

  const darkTheme = theme === "dark";

  return (
    <>
      <header className={styles.container}>
        <Link to={HOME_PAGE.path.pl}>
          <Logo className={classNames(styles.logo, { [styles.logoDark]: darkTheme })} />
        </Link>
        <div className={styles.itemsContainer}>
          <div className={styles.items}>
            {/* <button onClick={() => changeLocale(locale === "pl" ? "en" : "pl")}>CHANGE LANGUAGE123</button> */}
            {menuItems(t, "pl").map(({ name, path, section }) => {
              const isActive = checkActive(path);

              return (
                <button
                  key={name}
                  type="button"
                  // to={path}
                  className={classNames(styles.menuItem, {
                    [styles.activeMenuItem]: isActive && !section,
                    [styles.menuItemDark]: darkTheme,
                  })}
                  onClick={() => {
                    if (!section) {
                      if (path.startsWith("/")) {
                        navigate(`${path}`);
                      } else {
                        navigate(`/${path}`);
                      }
                      return;
                    }

                    if (section) {
                      navigate(`${path}?section=q${section}`, { preventScrollReset: true });
                      setTimeout(() => {
                        navigate(`${path}?section=${section}`, { preventScrollReset: true });
                      }, 0);
                    }
                  }}
                >
                  {name}
                </button>
              );
            })}
            <button
              type="button"
              // to={`${pathname}#${SECTIONS.contact}`}
              className={classNames(styles.menuItem, {
                [styles.menuItemDark]: darkTheme,
              })}
              onClick={() => {
                const contact = document.querySelector(`#${SECTIONS.contact}`);
                if (contact) contact.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {t("pages.contact")}
            </button>
          </div>
          <Hamburger isOpen={isMenuOpen} toggleMenu={toggleMenu} darkTheme={darkTheme} />
        </div>
      </header>
      <MobileMenu isOpen={isMenuOpen} closeMenu={handleMenuOpen(false)} />
    </>
  );
};
