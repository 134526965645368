import { home } from "./home.translations";
import { solutions } from "./solutions.translations";
import { useCase } from "./use-case.translations";
import { pricing } from "./pricing.translations";
import { finance } from "./finance.translations";
import { ai } from "./ai.translations";
import { industry } from "./industry.translations";
import { marketing } from "./marketing.translations";
import { company } from "./company.translations";

export const enTranslations = {
  home,
  solutions,
  useCase,
  pricing,
  finance,
  ai,
  industry,
  marketing,
  company,
};
