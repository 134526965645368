import React from "react";
import { Outlet } from "react-router-dom";

import { Page } from "components";
import { RouteConfig } from "types";

export const PageRoute: React.FC<RouteConfig> = ({ component: Component, ...routeConfig }) => {
  // TODO: react helmet

  return (
    <Page {...routeConfig}>
      <Component />
      <Outlet />
    </Page>
  );
};
