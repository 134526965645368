import { LandingPage, OfferDetailsPage, OffersPage, PartnershipPage, InvestPage, NotFoundPage } from "pages";
import { RouteConfig } from "types";
import {
  HOME_PAGE,
  OFFERS_PAGE,
  SINGLE_OFFER_PAGE,
  PARTNERSHIP_PAGE,
  INVEST_PAGE,
  NOT_FOUND_PAGE,
} from "constants/routes.constants";

export const routes: RouteConfig[] = [
  { ...HOME_PAGE, component: LandingPage },
  { ...OFFERS_PAGE, component: OffersPage },
  { ...SINGLE_OFFER_PAGE, component: OfferDetailsPage },
  { ...PARTNERSHIP_PAGE, component: PartnershipPage },
  { ...INVEST_PAGE, component: InvestPage },
  { ...NOT_FOUND_PAGE, component: NotFoundPage },
];
