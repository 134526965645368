import React from "react";
import { useTranslation } from "react-i18next";

import { Title } from "components";

import styles from "./last-year.module.scss";

export const LastYear: React.FC = () => {
  const { t } = useTranslation();

  const lastYear = [
    {
      title: t("invest.lastYear.transactions.title"),
      subtitle: t("invest.lastYear.transactions.subtitle"),
      description: t("invest.lastYear.transactions.description"),
    },
    {
      title: t("invest.lastYear.clients.title"),
      subtitle: t("invest.lastYear.clients.subtitle"),
      description: t("invest.lastYear.clients.description"),
    },
    {
      title: t("invest.lastYear.partners.title"),
      subtitle: t("invest.lastYear.partners.subtitle"),
      description: t("invest.lastYear.partners.description"),
    },
  ];

  return (
    <section className={styles.container}>
      <Title size="small">{t("invest.lastYear.title")}</Title>

      <div className={styles.rowContainer}>
        {lastYear.map(({ title, subtitle, description }) => (
          <div key={subtitle} className={styles.itemContainer}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
