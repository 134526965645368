import React from "react";

import { CircleArrowLeft, CircleArrowRight } from "assets/icons";

import styles from "./slider-buttons.module.scss";

interface Props {
  total: number;
  onLeftClick?: () => void;
  onRightClick?: () => void;
}

export const SliderButtons: React.FC<Props> = ({ onLeftClick, onRightClick, total }) => {
  return (
    <div className={styles.container}>
      <button type="button" className={styles.button} onClick={onLeftClick} disabled={total === 1}>
        <CircleArrowLeft />
      </button>
      <button type="button" className={styles.button} onClick={onRightClick} disabled={total === 1}>
        <CircleArrowRight />
      </button>
    </div>
  );
};
