export const partnership = {
  banner: {
    title: "Współpraca",
    description:
      "Jesteśmy partnerem na każdym etapie procesu związanego z nieruchomościami. Oferujemy unikalne rozwiązania, takie jak możliwość zamiany mieszkań, co pozwala dostosować przestrzeń do zmieniających się potrzeb.",
    button: "Zobacz szczegóły",
  },
  descriptionTitle: "Współpraca",
  description:
    "Jesteśmy partnerem na każdym etapie procesu związanego z nieruchomościami. Oferujemy unikalne rozwiązania, takie jak możliwość zamiany mieszkań, co pozwala dostosować przestrzeń do zmieniających się potrzeb. Nasza doświadczona drużyna zajmuje się pełną obsługą dokumentacyjną, gwarantując bezproblemową transakcję kupna lub sprzedaży.\n\nJako pośrednicy, podnosimy wartość nieruchomości naszych Klientów, wykorzystując nasze doświadczenie i wiedzę rynkową. Współpracujemy z cenionymi ekipami remontowymi, dzięki czemu możemy odnowić mieszkanie zgodnie z oczekiwaniami. Skupiamy się na doskonałym wykonaniu oraz estetyce, podnosząc atrakcyjność i funkcjonalność każdej nieruchomości.\n\nNasza współpraca to zrozumienie, profesjonalizm i innowacyjne podejście. Dążymy do osiągnięcia najlepszych wyników dla naszych Klientów, gwarantując kompleksową i zadowalającą obsługę nieruchomości.",

  sectionTitle: "Współpracuj razem z nami",
  section1: {
    title: "Zamiana przy kupnie nieruchomości",
    description:
      "Oferujemy wyjątkową opcję zamiany nieruchomości, umożliwiającą Ci swobodne przeniesienie się do nowego mieszkania. Kupując u nas, masz szansę wliczyć wartość swojej obecnej nieruchomości w cenę nowego lokum.\n\nTo sprytne rozwiązanie, pozwalające dostosować przestrzeń do zmieniających się potrzeb bez zbędnych komplikacji. Nasz zespół pomoże Ci w procesie zamiany, zapewniając pełną przejrzystość i profesjonalną obsługę. Bez względu na metraż mieszkania zostawionego w rozliczenie, dajemy Ci elastyczność i wygodę przy realizacji Twoich nieruchomościowych planów.",
  },
  section2: {
    title: "Podniesienie wartości i sprzedaż mieszkania.",
    description:
      "Nasze doświadczenie w pośrednictwie to skrót do udanej sprzedaży. Wykorzystujemy sprawdzone metody, podnosząc wartość nieruchomości i skracając czas transakcji. Dzięki prostym, skutecznym technikom nadajemy mieszkania atrakcyjności i wyjątkowego charakteru, przyciągając potencjalnych nabywców.\n\nNasza wycena jest oparta na partnerskim dialogu - Twój komfort i zgoda są dla nas kluczowe. Zaufaj naszemu podejściu, by osiągnąć maksymalny zysk przy minimalnym wysiłku.",
  },
  section3: {
    title: "Remont nieruchomości",
    description:
      "Nasza oferta to nie tylko remonty własnych mieszkań, ale również zaufanej ekipy remontowej pod Twoją dyspozycją. Dzięki współpracy z doświadczonymi fachowcami, dostarczamy usługi najwyższej jakości. Od drobnych poprawek po kompleksowe odnowienia, tworzymy przestrzenie spełniające Twoje oczekiwania.\n\nUmów się z nami na wycenę pracy, by rozpocząć transformację Twojego mieszkania już dziś.",
  },
};
